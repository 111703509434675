import Joi from "joi";
import { join } from "lodash";

export const resourceTypes = {
  clientType: "clientType",
  institutionName: "institutionName",
};

export const commissionTypes = {
  flatType: "flatAmount",
  tieredType: "tiered",
};

export const productCatalogSchema = Joi.object({
  resourceType: Joi.string().required().messages({
    "string.empty": "You must enter resource type",
  }),
  clientType: Joi.string().when("resourceType", {
    is: resourceTypes.clientType,
    then: Joi.string().required().messages({
      "string.empty": "You must enter client type",
    }),
    otherwise: Joi.string().optional().allow(null),
  }),
  instituteIds: Joi.array().when("resourceType", {
    is: resourceTypes.institutionName,
    then: Joi.array().min(1).required().messages({
      "array.min": "You must select institute",
    }),
    otherwise: Joi.array().optional(),
  }),

  // pricing: Joi.number().required().messages({
  //   "string.empty": "You must enter the Price",
  // }),
  currency: Joi.string().required().messages({
    "string.empty": "You must enter the currency",
  }),

  recalculationType: Joi.string().required().messages({
    "string.empty": "Select a recalculation type",
  }),

  isCutOffDate: Joi.boolean().required(),
  cutOffDate: Joi.string().when("isCutOffDate", {
    is: true,
    then: Joi.string().required().messages({
      "string.empty": "You must select the date",
    }),
    otherwise: Joi.string().optional().allow(null),
  }),

  isTaxDeducted: Joi.boolean().required(),
  taxDeductedValueType: Joi.string().when("isTaxDeducted", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.string().optional(),
  }),
  taxDeductedValue: Joi.number().when("isTaxDeducted", {
    is: true,
    then: Joi.number().positive().required().messages({
      "number.empty": "Select a tax deducted type",
    }),
    otherwise: Joi.number().optional().allow(null),
  }),

  isTransactionFee: Joi.boolean().required(),
  transactionFeeValueType: Joi.string().when("isTransactionFee", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.string().optional(),
  }),
  transactionFeeValue: Joi.string().when("isTransactionFee", {
    is: true,
    then: Joi.string()
      .regex(/^[,. 0-9]+$/)
      .required()
      .messages({
        "string.empty": "Select a Transaction Fee type",
      }),
    otherwise: Joi.string().optional(),
  }),

  isPartnerRevShare: Joi.boolean().required(),
  commissionType: Joi.string().when("isPartnerRevShare", {
    is: true,
    then: Joi.string().required().messages({
      "string.empty": "You must select Commission Structure ",
    }),
    otherwise: Joi.string().optional(),
  }),

  flatTypeValueType: Joi.string().when("commissionType", {
    is: commissionTypes.flatType,
    then: Joi.string().required(),
    otherwise: Joi.string().optional(),
  }),
  flatTypeValue: Joi.number().when("commissionType", {
    is: commissionTypes.flatType,
    then: Joi.number().required().messages({
      "number.empty": "You must Select a Flat type",
    }),
    otherwise: Joi.number().optional(),
  }),
  clipCommision: Joi.any().when("commissionType", {
    is: commissionTypes.tieredType,
    then: Joi.array().items({
      from: Joi.string().strict().trim().required().messages({
        "string.empty": "You must enter the From value",
      }),
      to: Joi.string().strict().trim().required().messages({
        "string.empty": "You must enter the To value",
      }),
      value: Joi.number().required().messages({
        "number.empty": "You must enter the Value",
      }),
    }),
    otherwise: Joi.any(),
  }),
  clipCommissionTypes: Joi.any(),
});
