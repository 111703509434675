import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "../../../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { Controller } from "react-hook-form";
import { values } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  table: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    padding: "10px",
  },
}));

export const TieredTest = ({
  control,
  errors,
  index,
  clipCommision,
  handleRemoveClick,
  values,
}) => {
  const classes = useStyles();
  const [addRow, setAddRow] = useState([
    { from: 0, to: 0, amount: 0.0, percentage: 0 },
  ]);

  return (
    <div className={classes.root}>
      <div style={{ paddingRight: "5px" }}>
        <Grid container xs={12}>
          <Grid
            item
            xs={3}
            style={{
              marginTop: "12px",
              marginLeft: "10px",
            }}
          >
            <Controller
              control={control}
              name={`clipCommision[${index}].from`}
              id="from"
              fullWidth
              render={({ onChange, value }) => {
                return (
                  <TextField
                    size="small"
                    type="number"
                    id="clipCommision.from"
                    placeholder="Value"
                    style={{ width: "180px" }}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      min:
                        index === 0
                          ? 0
                          : parseFloat(clipCommision[index - 1].to),
                    }}
                    onChange={onChange}
                    value={value}
                    error={!!errors?.clipCommision?.[index]?.from}
                    helperText={errors?.clipCommision?.[index]?.from?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            <Controller
              control={control}
              name={`clipCommision[${index}].to`}
              id="clipCommision.to"
              fullWidth
              render={({ onChange, value }) => {
                return (
                  <TextField
                    size="small"
                    type="number"
                    id="clipCommision.to"
                    placeholder="Value"
                    style={{ width: "180px" }}
                    variant="outlined"
                    inputProps={{
                      min:
                        clipCommision[index] && index === 0
                          ? parseFloat(clipCommision[index].from) + 1
                          : parseFloat(clipCommision[index - 1].to) + 1,
                    }}
                    fullWidth
                    onChange={onChange}
                    value={parseFloat(value)}
                    error={!!errors?.clipCommision?.[index]?.to}
                    helperText={errors?.clipCommision?.[index]?.to?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              marginTop: "10px",
            }}
          >
            <Controller
              control={control}
              name={`clipCommision[${index}].value`}
              id="clipCommision.value"
              fullWidth
              render={({ onChange, value }) => {
                return (
                  <TextField
                    size="small"
                    type="text"
                    id="clipCommision.value"
                    placeholder="Value"
                    style={{ width: "180px" }}
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        inputmode: "numeric",
                      },
                    }}
                    error={!!errors?.clipCommision?.[index]?.value}
                    helperText={errors?.clipCommision?.[index]?.value?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              marginTop: "10px",
            }}
          >
            {clipCommision.length > 1 && (
              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                color="danger"
                disableElevation
                onClick={() => handleRemoveClick(index)}
              >
                <DeleteIcon />
                Remove
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
